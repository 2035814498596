<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  async created() {
    // 更新后删除用户本地缓存
    const param = "3eb_webgl_version";
    let version = Cookies.get("version");
    let line_ver = "";
    console.log("~~~~~~~~~~~~~~~~~~~~~", version);
    await axios
      .get(
        "https://mtlpvkibn7drjiy3vxo2qwi4qa0duvhh.lambda-url.us-east-1.on.aws/"
      )
      .then((res) => {
        line_ver = res.data[param];
      });
    if (version !== line_ver) {
      var deleteRequest = window.indexedDB.deleteDatabase("UnityCache");
      deleteRequest.onsuccess = function (event) {
        console.log("Database deleted successfully");
      };
      deleteRequest.onerror = function (event) {
        console.log("Error deleting database");
      };
      Cookies.set("version", line_ver, { expires: 7 });
    }
  },
  mounted() {
    // 禁止拖动图片
    ["dragstart"].forEach(function (ev) {
      document.addEventListener(ev, function (ev) {
        ev.preventDefault();
      });
    });
  },
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="less">
@import "assets/css/main.css";
body {
  background: #f1efe8;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
#app {
  font-family: "Microsoft YaHei", Arial, "黑体", sans-serif;
}
.grecaptcha-badge {
    display: none;
}
</style>
