import axios from 'axios'
import CryptoJS from 'crypto-js/crypto-js'
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import env from '@/envconfig/index';
// 根据环境不同引入不同api地址
// import {
//   baseApi
// } from '@/config'

//获取设备唯一id
async function getDeviceId() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise
  const result = await fp.get()
  // This is the visitor identifier:
  const visitorId = result.visitorId
  if (!localStorage.getItem('deviceId')) {
    window.localStorage.setItem('deviceId', visitorId)
  }
}
getDeviceId()

function JsonToUrl(data) {
  var tempArr = [];
  for (var i in data) {
    var key = i;
    var value = data[i];
    tempArr.push(key + '=' + value);
  }
  var urlParamsStr = tempArr.join('&');
  return urlParamsStr;
}

function changeMethods(key) {
  switch (key) {
    case 'get':
      return 'GET'
    case 'post':
      return 'POST'
    case 'delete':
      return 'DELETE'
    default:
      break;
  }
}
// create an axios instance
const service = axios.create({
  baseURL: window.config.baseApi, // url = base api url + request url
  withCredentials: true,
  timeout: 20000
})
// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    return axios({
      url: `${config.baseURL}api/v3/general/servertime`,
      method: 'get',
    }).then(res => {
      if (res.data && res.data.result == 1) {
        let body = JSON.stringify(config.data) || JsonToUrl(config.params) || 'null',
          url = config.url, //
          method = changeMethods(config.method),
          AppType = localStorage.getItem('isMobile') ? 2 : 6,
          LoginType = localStorage.getItem('isMobile') ? '1' : 2,
          DeviceId = window.localStorage.getItem('deviceId'),
          timestamp = res.data.data,
          str = body + url + method + DeviceId + timestamp;
        console.log('str', str)
        let sign = CryptoJS.HmacSHA256(str, env.key),
          codestr = body + url + method + AppType + DeviceId + timestamp, //验证码接口 字符串拼接规则
          // sign2 = CryptoJS.HmacSHA256(codestr, env.codekey),
          hashInBase64 = CryptoJS.enc.Base64.stringify(sign); //手机验证码hash拼接规则
        // hashInBase64code = CryptoJS.enc.Base64.stringify(sign2); 

        config.headers['Authorization'] = localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ''
        config.headers['sign-version'] = '1.0'
        config.headers['Login-Type'] = LoginType
        config.headers['Sign'] = hashInBase64
        config.headers['version'] = '1.1.8'
        config.headers['app-type'] = AppType
        config.headers['Device'] = 'web'
        config.headers['lang'] = navigator.language
        config.headers['Timestamp'] = timestamp
        config.headers['Device-ID'] = DeviceId
        config.headers['app-id'] = 2
        config.headers['app-lang'] = 'zh'
        config.headers['Content-Type'] = 'application/json;charset=utf-8'
        return config
      }
    })
    // AppType { get; set; }//2-May,4-新元
    // LoginType { get; set; } // 1-APP, 2-Web, 3-SDK

  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    return Promise.resolve(res)
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

service.defaults.transformResponse = [
  data => {
    return jsonlint.parse(data)
  }
]

export default service