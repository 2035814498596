import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import 'hover.css'
import animate from 'animate.css';
import api from './utils/api'
//埋点
import {
  init,
  track,
} from '@amplitude/analytics-browser';
// 引入rem
import './utils/rem'

Vue.config.productionTip = false
Vue.prototype.$api = api

// 打开vuetools
Vue.config.devtools = true

//埋点
init('c6405b046b43ab89d7b852cb00796edd')
Vue.prototype.$amplitude_track = track;

Vue.use(ElementUI)
Vue.use(animate)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')