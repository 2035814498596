import Vue from 'vue'
import VueRouter from 'vue-router'
import {isMobile} from '@/utils/util.js'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('../views/game.vue'),
    meta: {
      isAuth: true
    }
  },
  // {
  //   path: '/park',
  //   name: 'park',
  //   component: () => import('../views/park.vue')
  // },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../components/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//全局前置路由守卫——需要登录权限
router.beforeEach((to, from, next) => {
  //如果路由需要跳转 且不是移动端
  if (to.meta.isAuth&&!isMobile()) {
    //判断 
    if (localStorage.getItem("user_id") && localStorage.getItem("discord_id")) {
      next() //放行
    } else {
      router.push("/")
    }
  } else {
    // 否则，放行
    next()
  }
})
export default router