import request from '@/utils/requestsdk'
export default {
    //钱包签名
    //api/v5/auth/blockchain/nonce获取区块链登录用到的随机数
    nonce: function (params) {
        return request({
            url: '/api/v5/auth/blockchain/nonce',
            method: 'get',
            params
        })
    },
    //api/v5/auth/metamask/login使用metamask登录
    metamaskLogin: function (data) {
        return request({
            url: '/api/v5/auth/wallet/login',
            method: 'post',
            data
        })
    },

    // 更新游戏完成时间
    setFinishTime(data) {
        return request({
            url: '/api/v2/threeearbunny/setFinishTime',
            method: 'post',
            data
        })
    },
    // 获取多个排行榜
    getRankLists(data) {
        return request({
            url: '/api/v3/threeearbunny/getRankList',
            method: 'post',
            data
        })
    },
    // 获取整体游戏记录
    getTotalRecord(params) {
        return request({
            url: '/api/v2/threeearbunny/getTotalRecord',
            method: 'get',
            params
        })
    },
    // 获取个人游戏记录
    getUserRecord(data) {
        return request({
            url: '/api/v3/threeearbunny/getUserRecord',
            method: 'post',
            data
        })
    },
    // 更新用户名
    profile(data) {
        return request({
            url: '/api/v2/me/profile',
            method: 'post',
            data
        })
    },
    // 获取用户名
    getMe(params) {
        return request({
            url: '/api/v2/me',
            method: 'get',
            params
        })
    },
    // 获取同步服务器时间
    serverTime(params) {
        return request({
            url: '/api/v3/general/servertime',
            method: 'get',
            params
        })
    },
    // google人机校验
    checksum(data) {
        return request({
            url: '/api/v2/threeearbunny/verify-recaptcha',
            method: 'post',
            data
        })
    },
    // 消耗萝卜积分换取游戏次数
    buyTimes(data,token) {
        return request({
            url: `/api/v2/threeearbunny/${data.game_key}/increasePlayTimeByPoint?token=${token}`,
            method: 'post',
            data
        })
    },
    // 获取商品清单
    getProducts(params) {
        return request({
            url: `/api/v2/products/6,7,8,9,11`,
            method: 'get',
            params
        })
    },
    // 未登录获取商品清单
    getNOTloginProducts(params) {
        return request({
            url: `/api/v2/public/threeearbunny/product/6,7,8,9,11`,
            method: 'get',
            params
        })
    },
    //购买商品
    buyProducts(data) {
        return request({
            url: '/api/v2/threeearbunny/purchaseProduct',
            method: 'post',
            data
        })
    },
    //获取积分明细与余额
    getUserPointRecord(data) {
        return request({
            url: '/api/v2/threeearbunny/getUserPointRecord',
            method: 'post',
            data
        })
    },
    //每周分享获取积分
    weeklyShare(data) {
        return request({
            url: '/api/v2/threeearbunny/weeklyShare',
            method: 'post',
            data
        })
    },
    //绑定三方账户
    bindThirdPartAccount(data) {
        return request({
            url: '/api/v2/users/bindThirdPartAccount',
            method: 'post',
            data
        })
    },
    //获取游戏倒计时
    getGameCountDown(params) {
        return request({
            url: '/api/v2/threeearbunny/getGameCountDown',
            method: 'get',
            params
        })
    },
    //进入房间(创建房间) 
    enterRoom(data) {
      return request({
          url: '/api/v2/threeearbunny/enterRoom',
          method: 'post',
          data
      })
    },
    //离开房间
    quitRoom(roomId) {
      return request({
        url: `/api/v2/threeearbunny/quitRoom`,
        method: 'post',
        data:{
          room_id:roomId
        }
      })
    },
    //结束游戏
    gameOver(roomId) {
      return request({
          url: `/api/v2/threeearbunny/gameOver`,
          method: 'post',
          data:{
            room_id:roomId
          }
      })
    },
    //上报游戏时间 
    setGameRecord(data) {
      return request({
          url: '/api/v2/threeearbunny/setGameRecord',
          method:'post',
          data
      })
    },
    //游戏开始/api/v2/threeearbunny/gameStart/{roomId}
    gameStart(roomId) {
      return request({
          url: `/api/v2/threeearbunny/gameStart`,
          method: 'post',
          data:{
            room_id:roomId
          }
      })
    },
    //获取多用户信息
    users (params) {
      return request({
        url:'/api/v2/users',
        method: 'GET',
        params
      })
    },
}